@import "navigation";

.site-header {
    .nav-link {
        @include font-size(16px);
        letter-spacing: 1px;
    }   
    .store-switcher-storeswitcher .dropdown-menu li {
        .dropdown-item {
            color: $tertiary;
        }
    }
}
.header-menu-padding-small, .header-menu-padding-medium {
    .nav-link {
        @include font-size(15px);
    }
}

.sticky-header {
    &.border-bottom {
        border-bottom: none !important;
    }
}

@include media-breakpoint-up(lg) {
    .minify-header {
        &.service-cloud-agent {
            .site-header:not(.site-header_slim) {
                transform: translateY(0px);
            }
        }
        .sticky-header {
            .navbar-brand,
            .sticky-header-minicart {
                .logo {
                    width: $logo-width-lg;
                }
            } 
            .navbar-brand {
                @include make-col(2);
            }

            .header-nav {
                @include make-col(8);
            }

            .level-1 > .nav-item {
                padding: 0.5em 2rem;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .minify-header {
        .level-1 > .nav-item {
            padding: 0.5em 2rem;
        }
    }
}

@media (max-width: 375px) { 
    .minify-header {
        .non-sticky-header {
            &.blog-enabled-mobile {
                .navbar-brand {
                    padding-right: 1px;
                }
                .minicart {
                    margin-left: -10px;
                }
            }
        }
    }
 }