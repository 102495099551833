@import "~core/components/navigation";

@include media-breakpoint-up(lg) {
    .navbar-nav > li {
        font-weight: $font-weight-bold;
        line-height: 1.5;
    }

    .level-2 {
        .menu-main-template {
            .lvl-2-link {
                font-family: $site-menu-level2-font;
                font-weight: $font-weight-semi-bold !important;
            }
        }
    }

    .mega-menu__item {
        .mega-menu__sub-wrapper {
            .menu-items-container{
                .menu-category-name{
                    font-weight: $font-weight-semi-bold;
                }
            }
        }
    }
}

.minify-header {
    .sticky-header {
        .level-1 > .nav-item {
            padding: 0.2em 0.9rem;
        }
    }
}
@include media-breakpoint-up(sm) { 
    .sticky-header {
        .level-1 > .nav-item {
            padding: 0.2em 0.9rem !important;
        }
    }
 }
@include media-breakpoint-up(md) { 
    .sticky-header {
        .level-1 > .nav-item {
            padding: 0.2em 0.9rem !important;
        }
    }
 }
@include media-breakpoint-up(lg) { 
    .sticky-header {
        .level-1 > .nav-item {
            padding: 0.2em 0.9rem !important;
        }
    }
 }
@include media-breakpoint-up(xl) { 
    .sticky-header {
        .level-1 > .nav-item {
            padding: 0.2em 1.8rem !important;
        }
    }
 }

