@import "~base/components/toastMessage";

.product-detail {
    .lens-attributes {
        background-color: $tertiary;
        padding: 0px 10px;
    }
    .attr-label {
        color : $ocher;
        font-weight: 800;
    }
    .attr-value {
        color : $secondary;
        font-weight: 800;
    }
    .long-description_content {
        .attr-value {
            padding-left: 5px;
        }
    }
    .item-details-section {
        background-color: $light-grey;
    }
    hr {
        border-top: 1px solid $ocher;
    }
    .color-attribute {
        .swatch {
            &.selected {
                border-color: $ocher;
            }
        }
    }
    .short-description {
        background-color: $secondary;
        color: $tertiary;
    }
    .details-section ul {
        padding-left : 0px;
    }
    @include media-breakpoint-up(lg) {
        .details-section {
            border-left: 1px solid $ocher;
        }
        .details-section-br {
            border-right: 1px solid $ocher;
        }
    }
    .long-description .title {
        color : $secondary;
    }
    .manufacturer-sku-section, .attributes .attribute label, .color-and-sku {
        display: none !important;
    }
    .features-section .long-description_content div {
        margin-bottom : 0.25rem;
    }
    ul.dashed {
        list-style-type: none;
    }
    ul.dashed > li:before {
        content: "-";
        margin-right: 0.2rem;
    }

    .product-delivery-details{
        padding: 0px 10px;
    }
}