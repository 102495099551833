$primary: #000000 !default;
$secondary: #000000 !default;
$tertiary: #ffffff !default;
$ocher: #bc8c3d !default;
$accent: $ocher !default;

$action-primary: #bc8c3d !default;
$default-hero-color: #C4C3B5 !default;

$gray-100: #f8f8f8 !default;
$gray-200: #e9ecef !default;
$gray-300: #e6e6e6 !default;
$gray-400: #ced4da !default;
$gray-500: #ababab !default;
$gray-600: #6c757d !default;
$gray-700: #565656 !default;
$gray-900: #393939 !default;

$logo-width-lg: 160px !default;
$logo-width-small: 108px !default;
$logo-width-xxl: 300px !default;
$logo-max-width: 150px !default;
$logo-max-height: 40px !default;
$logo-max-height-mobile: 33px !default;


@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@100;200;300;400;500;600;700;800&display=swap');

$font-family-title: "Cormorant Garamond", sans-serif !default;
$font-family-global: "Open Sans", sans-serif !default;
$font-family-base: $font-family-global !default;
$font-family-accent: $font-family-global !default; 
$font-family-roboto: "Roboto", sans-serif !default;
$font-family-roboto-condensed: "Roboto Condensed", sans-serif !default;

$h1-font-family: $font-family-title !default;
$h2-font-family: $font-family-title !default;
$h3-font-family: $font-family-title !default;
$h4-font-family: $font-family-title !default;
$h5-font-family: $font-family-title !default;
$h6-font-family: $font-family-title !default;
$site-menu-level1-font: $font-family-title !default;
$site-menu-level2-font: $font-family-title !default;
$site-menu-level3-font: $font-family-title !default;
$font-weight-bold: 400 !default;
$font-weight-semi-bold: 600 !default;

$site-page-bg: $secondary !default;

// Header
$site-preheader-bg: $ocher !default;
$site-preheader-text: $secondary !default;
$site-main-menu-bg: $tertiary !default;
$site-main-menu-search-bg: $tertiary !default;
$site-main-menu-icon: $secondary !default;
$site-main-menu-border: $gray-300 !default;
$site-mobile-nav-bg: $tertiary !default;
$site-cart-background: $tertiary !default;
$site-cart-mobile-background: $tertiary !default;
$site-menu-item-header: $secondary !default;
$site-menu-item-text: $secondary !default;
$site-menu-item-link: $secondary !default;
$site-search-header: $secondary !default;
$site-menu-level3-bg: $secondary; 
$checkout-menu-item-text: $tertiary !default;
$checkout-menu-item-link: $tertiary !default;

// Navbar
$navbar-light-color: $secondary !default;
$navbar-dark-color: $tertiary !default;
$navbar-light-hover-color: $secondary !default;
$navbar-dark-hover-color: $secondary !default;

// Footer
$site-footer-bg: $secondary !default;
$site-footer-reinsurance-bg: $secondary !default;
$site-footer-reinsurance-text: $tertiary !default;
$site-footer-header: $tertiary !default;
$site-footer-link: $tertiary !default;
$site-footer-social: $tertiary !default;
$site-footer-notice-bg: $secondary !default;
$site-footer-notice-text: $tertiary !default;
$site-footer-countryselector-text: $tertiary !default;
$site-footer-separator: $tertiary !default;

$btn-email-footer-color: $secondary !default;
$btn-email-footer-bgcolor: $gray-300 !default;
$btn-email-footer-border-color: $gray-300 !default;

// Search Refinement
$search-refinement-header-border: $ocher !default;
$search-refinement-size-selected-background: $ocher !default;

$category-banner-overlay-color: $tertiary !default;
$category-banner-header-overlay-color: $primary !default;

@import "~core/variables";
