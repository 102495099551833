@import 'variables';

@import '~core/global';

@import "components/header";
@import "components/headerSearch";
@import "components/productTiles";
@import "components/productCommon";

:root {
    --custom-product-name-font-family: #{$font-family-title};
    --custom-product-tile-content-alignment: center;
    --custom-product-tile-image-filter: none;
    --custom-product-tile-image-background-color: none;
    --custom-product-tile-image-ratio: 3/2;
    --custom-search-product-grid-gap: 2rem 1rem;
    --custom-product-tile-name-size: 1.2rem;
    --custom-product-tile-name-size-md-down: 1.2rem;
    --custom-product-tile-name-size-sm-down : 1rem;
    --custom-product-tile-price-size: 1.2rem;
    --custom-product-tile-price-size-md-down: 1.2rem;
    --custom-product-tile-price-size-sm-down: 1.1rem;
}