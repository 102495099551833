.site-search {
    .input-group {
        .search-field {
            text-align: center;
            font-weight: bold;
            color: $secondary;
            font-size: 15px;
        }
    }
}
