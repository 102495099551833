.product {
    .tile-body {
        .pdp-link {
            .link {
                letter-spacing: 1px;
                font-weight: 800;
                overflow: visible;
                white-space: normal;
            }
        }

        .price {
            font-family: $font-family-title;
            font-weight: 300;
            .starting,
            .range,
            .sales {
                font-weight: 300;
            }
        }
    }
}